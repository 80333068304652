<template>
    <el-container>
        <!-- 中间内容 -->
        <el-container>
            <el-main>
                <h2 class="depTitle" v-if="departmentId !== 'null'">科室医疗服务项目管理</h2>
                <div>
                    <el-form class="twoForm" :inline="true">
                        <el-form-item label="平均薪资年份：">
                            <el-select v-model="YearValue" placeholder="请选择" clearable @change="YearValueChange(YearValue)" class="xiangType">
                                <el-option v-for="item in Yearoptions" :key="item.id" :label="item.year" :value="item.year">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <div style="margin-right: 20px">
                            <el-input v-model="searchValue" placeholder="请输入项目名称或项目编码" prefix-icon="el-icon-search" class="index-search" @keyup.enter.native="searchList" @blur="searchList" clearable @clear="searchList">
                            </el-input>
                        </div>
                        <el-form-item label="项目分类：">
                            <el-select v-model="typeValue" placeholder="请选择" clearable @change="typeValueChange(typeValue)" class="xiangType">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                          <div class="index-add">
                            <el-button type="primary" v-if="synClick" @click="synProject" :loading="synClickTwo">同步医疗服务项目</el-button>
                            <el-button v-else type="info" :loading="synClickTwo"></el-button>
                          </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="index-add">
                                <el-button type="primary" v-if="cesuanClick" @click="downBusinessExcel" :loading="cesuanClickTwo">导出医疗服务项目</el-button>
                                <el-button v-else type="info" :loading="cesuanClickTwo"></el-button>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="index-add">
                                <el-upload ref="uploadone" class="upload-demo" :headers="token" :action="downurl" :data="{ departmentId: departId, year: YearValue }" :file-list="fileList" :limit="limitUpload" style="display: inline-block" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" :on-success="success" :on-progress="uploading">
                                    <el-button type="primary">导入数据</el-button>
                                </el-upload>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="index-add">
                                <el-button type="primary" @click="addClickdialogVisible">添加项目</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <el-table :data="tableData" border center :row-class-name="tableRowClassName" style="width: 99%">
                    <el-table-column type="index" :index="indexMethod" align="center" label="序号" width="55"></el-table-column>
                    <el-table-column prop="type" label="项目类型" align="center" width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type == 1">收费项目</div>
                            <div v-else-if="scope.row.type == 0">非收费项目</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="projectCode" label="项目编码" width="100" align="center">
                    </el-table-column>
                    <el-table-column prop="priceCode" label="收费编码" align="center" width="110">
                    </el-table-column>
                    <el-table-column prop="name" label="项目名称" align="center" width="200">
                    </el-table-column>
                    <el-table-column prop="consumables" label="耗材" align="center" width="55">
                    </el-table-column>
                    <el-table-column prop="doctor" label="医生" align="center" width="55">
                    </el-table-column>
                    <el-table-column prop="nursing" label="护士" align="center" width="55">
                    </el-table-column>
                    <el-table-column prop="technician" label="技师" align="center" width="55">
                    </el-table-column>
                    <el-table-column prop="work" label="工勤" align="center" width="55">
                    </el-table-column>
                    <el-table-column prop="average" label="耗时(分钟)" align="center" width="60">
                    </el-table-column>
                    <el-table-column prop="skillClass" label="技术难度分类" align="center" width="80">
                    </el-table-column>
                    <el-table-column prop="skill" label="技术难度系数" align="center" width="80">
                    </el-table-column>
                    <el-table-column prop="riskClass" label="风险程度分类" align="center" width="80">
                    </el-table-column>
                    <el-table-column prop="risk" label="风险程度系数" align="center" width="80">
                    </el-table-column>
                    <el-table-column prop="company" label="单位" align="center" width="55">
                    </el-table-column>
                    <el-table-column prop="currentPrice" label="现行价格" align="center" width="60">
                    </el-table-column>
                    <el-table-column prop="offset" label="标化价值偏移" align="center" width="70">
                    </el-table-column>
                    <el-table-column prop="priceRatio" label="标化价值" align="center" width="80">
                    </el-table-column>
                  <el-table-column prop="medicalSort" label="医嘱小类" align="center" width="80">
                  </el-table-column>
                  <el-table-column prop="projectScore" label="项目系数" align="center" width="80">
                  </el-table-column>
                    <el-table-column label="操作" align="center" width="180">
                        <template style="display: flex" slot-scope="scope">

                          <el-tooltip class="item" effect="dark" content="标准项目" placement="top-start">
                            <!-- 标准项目 -->
                            <el-button size="mini" type="primary" class="startBtnJizu" @click="editStandard(scope.row)">
                                <i class="
                    icon
                    iconfont
                    icon-biaojiweizhongdian2-copy
                    startActive
                  " v-if="scope.row.standardProject == 0"></i>

                                <i class="icon iconfont icon-biaojiweizhongdian2-copy" v-else></i>
                            </el-button>
                          </el-tooltip>
                          <el-tooltip class="item" effect="dark" content="基准项目" placement="top-start">
                            <!-- 基准项目 -->
                            <el-button size="mini" type="primary" class="startBtn" @click="editStart(scope.row)">
                                <i class="el-icon-star-on startActive" v-if="scope.row.base == 0"></i>
                                <i class="el-icon-star-on" v-else></i>
                            </el-button>
                          </el-tooltip>
                            <el-button size="mini" type="primary" @click="editClickdialogVisible(scope.row)"><i class="el-icon-edit"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[100, 200, 300, 400]" :page-size="pageSize" layout=" prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击添加项目弹出框 -->
        <el-dialog title="添加科室医疗服务项目管理" :visible.sync="adddialogVisible" width="769px" top="2vh" append-to-body :before-close="adddialogVisibleClose">
            <el-form style="width: 100%" :model="AddTwoModel" ref="AddTwoRef" :rules="AddTwoRules" :inline="true" label-width="130px">
                <el-form-item label="项目名称：" prop="name">
                    <el-input v-model="AddTwoModel.name" placeholder="请填写项目名称"></el-input>
                </el-form-item>
                <el-form-item label="项目类型：" prop="type">
                    <el-select v-model="AddTwoModel.type" placeholder="请选择">
                        <el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收费编码：" prop="priceCode">
                    <el-input v-model="AddTwoModel.priceCode" placeholder="请填写收费编码"></el-input>
                </el-form-item>
                <el-form-item label="项目编码：" prop="projectCode">
                    <el-input v-model="AddTwoModel.projectCode" placeholder="请填写项目编码"></el-input>
                </el-form-item>
                <el-form-item label="耗材：" prop="consumables">
                    <el-input v-model="AddTwoModel.consumables" placeholder="请填写耗材"></el-input>
                </el-form-item>
                <el-form-item label="医生：" prop="doctor">
                    <el-input v-model="AddTwoModel.doctor" placeholder="请填写医生"></el-input>
                </el-form-item>
                <el-form-item label="护士：" prop="nursing">
                    <el-input v-model="AddTwoModel.nursing" placeholder="请填写护士"></el-input>
                </el-form-item>
                <el-form-item label="技师：" prop="technician">
                    <el-input v-model="AddTwoModel.technician" placeholder="请填写技师"></el-input>
                </el-form-item>
                <el-form-item label="工勤：" prop="work">
                    <el-input v-model="AddTwoModel.work" placeholder="请填写工勤"></el-input>
                </el-form-item>
                <el-form-item label="耗时：" prop="average">
                    <el-input v-model="AddTwoModel.average" placeholder="请填写耗时"></el-input>
                </el-form-item>
                <el-form-item label="技术难度分类：" prop="skillClass">
                    <el-input v-model="AddTwoModel.skillClass" placeholder="请填写技术难度分类"></el-input>
                </el-form-item>
                <el-form-item label="技术难度：" prop="skill">
                    <el-input v-model="AddTwoModel.skill" placeholder="请填写技术难度"></el-input>
                </el-form-item>
                <el-form-item label="风险程度分类：" prop="riskClass">
                    <el-input v-model="AddTwoModel.riskClass" placeholder="请填写风险程度分类"></el-input>
                </el-form-item>
                <el-form-item label="风险程度：" prop="risk">
                    <el-input v-model="AddTwoModel.risk" placeholder="请填写风险程度"></el-input>
                </el-form-item>
                <el-form-item label="现行价格：" prop="currentPrice">
                    <el-input v-model="AddTwoModel.currentPrice" placeholder="请填写现行价格"></el-input>
                </el-form-item>
                <el-form-item label="标化价格偏移：" prop="offset">
                    <el-input v-model="AddTwoModel.offset" placeholder="请填写标化价格偏移"></el-input>
                </el-form-item>
                <el-form-item label="医嘱小类：" prop="medadSub">
                  <el-select v-model="AddTwoModel.medadSub" placeholder="请选择医嘱小类">
                    <el-option v-for="item in medoptions" :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
              <el-form-item label="项目系数：" prop="proCoeff">
                <el-input v-model="AddTwoModel.proCoeff" placeholder="请填写项目系数"></el-input>
              </el-form-item>
<!--              <el-form-item label="xxxx：" prop="" style="visibility: hidden">-->
<!--                  <el-input></el-input>-->
<!--              </el-form-item>-->
                <el-form-item style="width: 100%">
                    <div class="btnCen">
                        <el-button class="depar-dia" type="primary" :loading="addItemLoading" @click="addItem">添加</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 点击操作修改弹出框 -->
        <el-dialog title="修改科室医疗服务项目管理" :visible.sync="editdialogVisible" width="769px" top="2vh" append-to-body :before-close="editdialogVisibleClose">
            <el-form style="width: 100%" :model="EditTwoModel" ref="EditTwoRef" :rules="EditTwoRules" :inline="true" label-width="130px">
                <el-form-item label="项目名称：" prop="name">
                    <el-input v-model="EditTwoModel.name"></el-input>
                </el-form-item>
                <el-form-item label="项目类型：" prop="type">
                    <el-select v-model="EditTwoModel.type" placeholder="请选择">
                        <el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收费编码：" prop="priceCode">
                    <el-input v-model="EditTwoModel.priceCode"></el-input>
                </el-form-item>
                <el-form-item label="项目编码：" prop="projectCode">
                    <el-input v-model="EditTwoModel.projectCode"></el-input>
                </el-form-item>
                <el-form-item label="耗材：" prop="consumables">
                    <el-input v-model="EditTwoModel.consumables"></el-input>
                </el-form-item>
                <el-form-item label="医生：" prop="doctor">
                    <el-input v-model="EditTwoModel.doctor"></el-input>
                </el-form-item>
                <el-form-item label="护士：" prop="nursing">
                    <el-input v-model="EditTwoModel.nursing"></el-input>
                </el-form-item>
                <el-form-item label="技师：" prop="technician">
                    <el-input v-model="EditTwoModel.technician"></el-input>
                </el-form-item>
                <el-form-item label="工勤：" prop="work">
                    <el-input v-model="EditTwoModel.work"></el-input>
                </el-form-item>
                <el-form-item label="耗时：" prop="average">
                    <el-input v-model="EditTwoModel.average"></el-input>
                </el-form-item>
                <el-form-item label="技术难度分类：" prop="skillClass">
                    <el-input v-model="EditTwoModel.skillClass"></el-input>
                </el-form-item>
                <el-form-item label="技术难度：" prop="skill">
                    <el-input v-model="EditTwoModel.skill"></el-input>
                </el-form-item>
                <el-form-item label="风险程度分类：" prop="riskClass">
                    <el-input v-model="EditTwoModel.riskClass"></el-input>
                </el-form-item>
                <el-form-item label="风险程度：" prop="risk">
                    <el-input v-model="EditTwoModel.risk"></el-input>
                </el-form-item>
                <el-form-item label="现行价格：" prop="currentPrice">
                    <el-input v-model="EditTwoModel.currentPrice"></el-input>
                </el-form-item>
                <el-form-item label="标化价格偏移：" prop="offset">
                    <el-input v-model="EditTwoModel.offset"></el-input>
                </el-form-item>
              <el-form-item label="医嘱小类：" prop="medadSub">
                <el-select v-model="EditTwoModel.medadSub" placeholder="请选择医嘱小类">
                  <el-option v-for="item in medoptions" :key="item.name" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="项目系数：" prop="proCoeff">
                <el-input v-model="EditTwoModel.proCoeff" placeholder="请填写项目系数"></el-input>
              </el-form-item>
<!--              <el-form-item label="xxxx：" prop="" style="visibility: hidden">-->
<!--                <el-input></el-input>-->
<!--              </el-form-item>-->
                <el-form-item>
                  <div class="btnCen">
                    <el-button        v-if="cesuanClick"        type="primary"        :loading="editItemLoading"        @click="editItem"       >修改</el-button>
                    <el-button        v-else        type="info"        :loading="editItemLoading"       ></el-button>
                  </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="正在导入数据，请稍等~" :visible.sync="dialogVisibleloding" width="30%" :show-close="false" center append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
// 标准项目-国旗icon
import "@/assets/icon/jizhun/iconfont.css";
export default {
    props: ["keshiId", "hospitalName"],
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value && String(value) !== "0") {
                return callback(new Error("不能为空"));
            }
            setTimeout(() => {
                if (!/^\d+$|^\d*\.\d+$/g.test(value)) {
                    callback(new Error("请输入数字"));
                } else {
                    callback();
                }
            }, 100);
        };
        return {
            // 修改科室医疗服务项目管理的添加按钮
            editItemLoading: false,
            // 添加医疗管理的添加按钮
            addItemLoading: false,
            // 导出按钮置灰
            cesuanClick: true,
            cesuanClickTwo: false,

            synClick:true,
            synClickTwo:false,

            dialogVisibleloding: false,
            // 搜素的值
            searchValue: "",
            // 判断是科室用户还是医院用户
            winType: false,
            // 总页数
            total: 1,
            // 修改医疗项目
            EditTwoModel: {
                // 项目名称：
                name: "",
                // 项目类型：
                type: "",
                // 收费编码：
                priceCode: "",
                // 项目编码：
                projectCode: "",
                // 耗材：
                consumables: "",
                // 医生：
                doctor: "",
                // 护士：
                nursing: "",
                // 技师：
                technician: "",
                // 工勤：
                work: "",
                // 耗时：
                average: "",
                // 技术难度分类：
                skillClass: "",
                // 技术难度：
                skill: "",
                // 风险程度分类：
                riskClass: "",
                // 风险程度：
                risk: "",
                // 现行价格：
                currentPrice: "",
                // 标化价格偏移：
                offset: "1.0",
            //   医嘱小类
              medadSub:"",
              // 项目系数
              proCoeff:""
            },
            EditTwoRules: {
                // 项目名称：
                name: [
                    {
                        required: true,
                        message: "请输入项目名称",
                        trigger: "blur",
                    },
                ],
                // 项目类型：
                type: [
                    {
                        required: true,
                        message: "请选择项目类型",
                        trigger: "change",
                    },
                ],
                // 收费编码：
                priceCode: [
                    {
                        required: true,
                        message: "请输入收费编码",
                        trigger: "blur",
                    },
                ],
                // 项目编码：
                projectCode: [
                    {
                        required: true,
                        message: "请输入项目编码",
                        trigger: "blur",
                    },
                ],
                // 耗材：
                consumables: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 医生：
                doctor: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 护士：
                nursing: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 技师：
                technician: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 工勤：
                work: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 耗时：
                average: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 技术难度分类：
                skillClass: [
                    {
                        required: true,
                        message: "请输入技术难度分类",
                        trigger: "blur",
                    },
                ],
                // 技术难度：
                skill: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 风险程度分类：
                riskClass: [
                    {
                        required: true,
                        message: "请输入风险程度分类",
                        trigger: "blur",
                    },
                ],
                // 风险程度：
                risk: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 现行价格：
                currentPrice: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 标化价格偏移：
                offset: [
                    {
                        required: true,
                        message: "请输入标化价格偏移",
                        trigger: "blur",
                    },
                ],
              medadSub:[
                {
                  required: true,
                  message: "请选择医嘱小类",
                  trigger: "change",
                },
              ],
              // 项目系数
              proCoeff:[
                {
                  required: true,
                  message: "请输入项目系数",
                  trigger: "blur",
                },
              ],
            },
            // 添加医疗项目
            AddTwoModel: {
                // 项目名称：
                name: "",
                // 项目类型：
                type: "",
                // 收费编码：
                priceCode: "",
                // 项目编码：
                projectCode: "",
                // 耗材：
                consumables: "",
                // 医生：
                doctor: "",
                // 护士：
                nursing: "",
                // 技师：
                technician: "",
                // 工勤：
                work: "",
                // 耗时：
                average: "",
                // 技术难度分类：
                skillClass: "",
                // 技术难度：
                skill: "",
                // 风险程度分类：
                riskClass: "",
                // 风险程度：
                risk: "",
                // 现行价格：
                currentPrice: "",
                // 标化价格偏移：
                offset: "1.0",
              //   医嘱小类
              medadSub:"",
              // 项目系数
              proCoeff:""
            },
            AddTwoRules: {
              // 项目系数
              proCoeff:[
                {
                  required: true,
                  message: "请输入项目系数",
                  trigger: "blur",
                },
              ],
                // 项目名称：
                name: [
                    {
                        required: true,
                        message: "请输入项目名称",
                        trigger: "blur",
                    },
                ],
                // 项目类型：
                type: [
                    {
                        required: true,
                        message: "请选择项目类型",
                        trigger: "change",
                    },
                ],
                // 收费编码：
                priceCode: [
                    {
                        required: true,
                        message: "请输入收费编码",
                        trigger: "blur",
                    },
                ],
                // 项目编码：
                projectCode: [
                    {
                        required: true,
                        message: "请输入项目编码",
                        trigger: "blur",
                    },
                ],
                // 耗材：
                consumables: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 医生：
                doctor: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 护士：
                nursing: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 技师：
                technician: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 工勤：
                work: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 耗时：
                average: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 技术难度分类：
                skillClass: [
                    {
                        required: true,
                        message: "请输入技术难度分类",
                        trigger: "blur",
                    },
                ],
                // 技术难度：
                skill: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 风险程度分类：
                riskClass: [
                    {
                        required: true,
                        message: "请输入风险程度分类",
                        trigger: "blur",
                    },
                ],
                // 风险程度：
                risk: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 现行价格：
                currentPrice: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
                // 标化价格偏移：
                offset: [
                    {
                        required: true,
                        message: "请输入标化价格偏移",
                        trigger: "blur",
                    },
                ],
              medadSub:[
                {
                  required: true,
                  message: "请选择医嘱小类",
                  trigger: "change",
                },
              ]
            },
            // 每页页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            typeoptions: [
                {
                    value: "1",
                    label: "收费项目",
                },
                {
                    value: "0",
                    label: "非收费项目",
                },
            ],
          // 医嘱小类
          medoptions:[],
            options: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "收费项目",
                },
                {
                    value: "0",
                    label: "非收费项目",
                },
            ],
            Yearoptions: [],
            typeValue: "",
            YearValue: "",
            // 表格数据
            tableData: [],
            // 添加项目弹出框
            adddialogVisible: false,
            // 操作的修改弹出框
            editdialogVisible: false,
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            // 科室名称
            departmentName: window.sessionStorage.getItem("departmentName"),
            id: "",
            // 基准项目
            base: "",
            // 标准项目
            standardProject: "",
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            downurl: this.GLOBAL + "/openApi/integrated/importBusinessExcel",
            fileList: [], // 上传文件的列表
            limitUpload: 1, //最大允许上传个数
            userType: window.sessionStorage.getItem("type"),
            departId: this.keshiId,
            departNam: this.hospitalName,
        };
    },
    computed: {
        token() {
            return {
                Authorization:
                    "Bearer " + window.sessionStorage.getItem("token"),
            };
        },
    },
    watch: {
        keshiId: {
            handler(newName, oldName) {
                this.departId = newName;
                this.pageNum=1
                this.getBusinessList();
            },
            deep: true,
        },
        hospitalName: {
            handler(newName, oldName) {
                this.departNam = newName;
            },
            deep: true,
        },
    },
    created() {
        // 0 医院用户   1 科室用户
        // if (this.userType == "1") {
        //     this.departId = window.sessionStorage.getItem("departmentId");
        //     this.departNam = window.sessionStorage.getItem("departmentName");
        // }
        if (this.departmentId !== "null") {
            this.departId = window.sessionStorage.getItem("departmentId");
            this.departNam = window.sessionStorage.getItem("departmentName");
        }
        this.getBusinessList();

        // if (this.$route.query.departmentId) {
        //   this.departmentId = this.$route.query.departmentId;
        // } else {
        //   this.departmentId = window.sessionStorage.getItem("departmentId");
        // }
        this.wiType();
        // 获取医院所有年份 不分页
        this.getHospitalYear();
        // 进入医疗服务项目页面先查询 所用计算的年份
        this.getHospitalYearCalculation();
    },
    methods: {
        //设置表格翻页的序号递增
        indexMethod(index) {
            let pageNum = this.pageNum; // 当前页码
            let pageSize = this.pageSize; // 每页条数
            return index + 1 + (pageNum - 1) * pageSize; // 返回表格序号
        },
        // 同步医疗服务项目
        async synProject(){
          this.synClick = false;
          this.synClickTwo = true;
          // 同步医疗服务项目
          let data = {
            departmentId: this.departId, //科室id
          };
          let res = await this.$axios.synProject(data);
          // console.log(res);
          if (res.data.code == 401) {
            this.$router.push("/login");
          } else if (res.data.code == 500) {
            this.$message({
              message: "同步失败",
              type: "error",
            });
          } else if (res.status == 200) {
            this.$message({
              message: "同步成功",
              type: "success",
            });
            this.getBusinessList();
          }

          this.synClick = true;
          this.synClickTwo = false;
        },
        // 点击导出模板
        async downBusinessExcel() {
            this.cesuanClick = false;
            this.cesuanClickTwo = true;
            let data = {
                departmentId: this.departId, //科室id
            };
            let res = await this.$axios.downBusinessExcel(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 500) {
                this.$message({
                    message: "导出失败",
                    type: "error",
                });
            } else if (res.status == 200) {
                let blob = new Blob([res.data], { type: "application/x-xls" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                //配置下载的文件名
                link.download =
                    this.departNam + "医疗服务项目管理模板" + ".xls";
                link.click();
                this.cesuanClick = true;
                this.cesuanClickTwo = false;
            }
        },
        // 导入成功后执行此操作
        success(res) {
            // console.log(res,'导入');
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                setTimeout(() => {
                    this.$message.success("导入成功");
                    this.dialogVisibleloding = false;
                }, 1000);
                this.getBusinessList();
                this.$refs.uploadone.clearFiles();
            } else {
                this.$alert("请按照模板上传正确格式的excel", {
                    confirmButtonText: "确定",
                    callback: () => {
                        this.dialogVisibleloding = false;
                        this.$refs.uploadone.clearFiles();
                    },
                });
            }
        },
        // excel导入过程中
        uploading() {
            this.dialogVisibleloding = true;
        },
        // 获取医院所有年份 不分页
        async getHospitalYear() {
            let data = {
                hospitalId: this.hospitalId, //医院id
            };
            let res = await this.$axios.getHospitalYear(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 500) {
                this.$message({
                    message: "获取医院所有年份失败",
                    type: "error",
                });
            } else if (res.status == 200) {
                this.Yearoptions = res.data.data;
            }
        },
        // 进入医疗服务项目页面先查询 所用计算的年份
        async getHospitalYearCalculation() {
            let data = {
                hospitalId: this.hospitalId, //医院id
            };
            let res = await this.$axios.getHospitalYearCalculation(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 500) {
                this.$message({
                    message: "查询所用计算的年份失败",
                    type: "error",
                });
            } else if (res.status == 200) {
                this.YearValue = res.data.data;
                this.getBusinessList();
            }
        },
        // 年份状态选项卡
        YearValueChange(YearValue) {
            this.YearValue = YearValue;
            this.calculationPriceRatio();
        },
        // 根据年份计算标化价值
        async calculationPriceRatio() {
            let data = _qs.stringify({
                hospitalId: this.hospitalId, //医院id
                // departmentId: this.departmentId,
                year: this.YearValue,
            });
            let res = await this.$axios.calculationPriceRatio(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 500) {
                this.$message({
                    message: "请先用医院管理员去设置年份对应的薪资",
                    type: "error",
                });
            } else if (res.status == 200) {
                this.getBusinessList();
            }
        },
        // 表格第一行上色
        tableRowClassName({ row, rowIndex }) {
            if (row.base == "0" && rowIndex == 0) {
                return "oneBack";
            }
        },
        // 状态选项卡
        typeValueChange(typeValue) {
            this.pageNum = 1;
            this.typeValue = typeValue;
            this.getBusinessList();
        },
        searchList() {
            this.pageNum = 1;
            this.getBusinessList();
        },
        // 点击操作的修改时候取消正则
        editdialogVisibleClose() {
            this.editItemLoading = false;
            this.editdialogVisible = false;
            this.$refs.EditTwoRef.resetFields();
        },
        // 点击添加项目时候取消正则
        adddialogVisibleClose() {
            this.addItemLoading = false;
            this.adddialogVisible = false;
            this.$refs.AddTwoRef.resetFields();
        },
        // 判断是医院用户还是科室用户
        wiType() {
            if (window.sessionStorage.getItem("type") == 0) {
                this.winType = false;
            } else if (window.sessionStorage.getItem("type") == 1) {
                this.winType = true;
            }
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getBusinessList();
        },
        // 点击添加项目
        addClickdialogVisible() {
            this.adddialogVisible = true;
            this.departmentMedicalSort()
        },
      async departmentMedicalSort(){
          let departmentId=''
          if(window.sessionStorage.getItem('departmentId')!=='null'){
            departmentId=window.sessionStorage.getItem("departmentId")
          }else{
            departmentId=this.keshiId
          }
          let data={
            pageNum:'',
            pageSize:'',
            departmentId:departmentId
          }
        let { data: res } = await this.$axios.departmentMedicalSort(data);
        // console.log(res);
        if (res.code == 401) {
          this.$router.push("/login");
        } else if (res.code == 200) {
          this.medoptions = res.rows;
        }
      },
        // 点击添加项目的添加
        addItem() {
            this.$refs.AddTwoRef.validate(async (valid) => {
                if (!valid) return;
                this.addItemLoading = true;
                let data = _qs.stringify({
                    departmentId: this.departId, //科室id(登录成功后返回的)
                    type: this.AddTwoModel.type, //项目类型 0 非收费项目 1 收费项目
                    projectCode: this.AddTwoModel.projectCode, //项目编码
                    priceCode: this.AddTwoModel.priceCode, //收费编码
                    name: this.AddTwoModel.name, //项目名称
                    consumables: this.AddTwoModel.consumables, //耗材
                    doctor: this.AddTwoModel.doctor, //医生
                    nursing: this.AddTwoModel.nursing, //护士
                    technician: this.AddTwoModel.technician, //技师
                    work: this.AddTwoModel.work, //工勤
                    average: this.AddTwoModel.average, //耗时
                    skillClass: this.AddTwoModel.skillClass, //技术难度分类
                    skill: this.AddTwoModel.skill, //技术难度
                    riskClass: this.AddTwoModel.riskClass, //风险系数分类
                    risk: this.AddTwoModel.risk, //风险系数
                    company: "次", //单位
                    currentPrice: this.AddTwoModel.currentPrice, //现行价
                    offset: this.AddTwoModel.offset, //标化价值偏移
                    standardProject: 0, //标准项目
                    medicalSort:this.AddTwoModel.medadSub,//医嘱小类
                    projectScore:this.AddTwoModel.proCoeff,//项目系数
                });
                this.editDepartmentBusiness(data, "add");
            });
        },
        // 标准项目
        async editStandard(row) {
            // console.log(row)
            if (row.standardProject == 0) {
                this.standardProject = 1;
            } else {
                this.standardProject = 0;
            }
            let data = _qs.stringify({
                id: row.id,
                standardProject: this.standardProject,
                year: this.YearValue, //平均薪资年份
            });
            let res = await this.$axios.standardProjectPriceRatio(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                this.getBusinessList();
            } else{
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
        },
        // 点击操作的星星--基准项目
        async editStart(row) {
            if (row.base == 0) {
                this.base = 1;
            } else {
                this.base = 0;
            }
            let data = _qs.stringify({
                id: row.id, //项目id(修改时传)
                departmentId: this.departId, //科室id
                base: this.base,
            });
            let res = await this.$axios.updateBusinessBase(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 500) {
                this.$message({
                    message: "每个科室只能存在一个基准项目",
                    type: "error",
                });
            } else if (res.status == 200) {
                this.adddialogVisible = false;
                this.$message({
                    message: "修改基准项目成功",
                    type: "success",
                });
                this.getBusinessList();
            }
        },
        // 点击操作的修改
        editClickdialogVisible(row) {
            // console.log(row)
          this.departmentMedicalSort()
            // 项目名称：
            this.EditTwoModel.name = row.name;
            // 项目类型：
            this.EditTwoModel.type = row.type;
            // 收费编码：
            this.EditTwoModel.priceCode = row.priceCode;
            // 项目编码：
            this.EditTwoModel.projectCode = row.projectCode;
            // 耗材：
            this.EditTwoModel.consumables = row.consumables;
            // 医生：
            this.EditTwoModel.doctor = row.doctor;
            // 护士：
            this.EditTwoModel.nursing = row.nursing;
            // 技师：
            this.EditTwoModel.technician = row.technician;
            // 工勤：
            this.EditTwoModel.work = row.work;
            // 耗时：
            this.EditTwoModel.average = row.average;
            // 技术难度分类：
            this.EditTwoModel.skillClass = row.skillClass;
            // 技术难度：
            this.EditTwoModel.skill = row.skill;
            // 风险程度分类：
            this.EditTwoModel.riskClass = row.riskClass;
            // 风险程度：
            this.EditTwoModel.risk = row.risk;
            // 现行价格：
            this.EditTwoModel.currentPrice = row.currentPrice;
            // 标化价格偏移：
            this.EditTwoModel.offset = row.offset;
            //   医嘱小类：
            this.EditTwoModel.medadSub = row.medicalSort;
            this.id = row.id;
            this.editdialogVisible = true;
          this.EditTwoModel.proCoeff=row.projectScore//项目系数
        },
        // 点击操作修改的添加
        editItem() {
            this.$refs.EditTwoRef.validate(async (valid) => {
                if (valid) {
                    this.cesuanClick = false;
                    this.editItemLoading = true;
                    let data = _qs.stringify({
                        id: this.id, //项目id(修改时传)
                        departmentId: this.departId, //科室id(登录成功后返回的)
                        type: this.EditTwoModel.type, //项目类型 0 非收费项目 1 收费项目
                        projectCode: this.EditTwoModel.projectCode, //项目编码
                        priceCode: this.EditTwoModel.priceCode, //收费编码
                        name: this.EditTwoModel.name, //项目名称
                        consumables: this.EditTwoModel.consumables, //耗材
                        doctor: this.EditTwoModel.doctor, //医生
                        nursing: this.EditTwoModel.nursing, //护士
                        technician: this.EditTwoModel.technician, //技师
                        work: this.EditTwoModel.work, //工勤
                        average: this.EditTwoModel.average, //耗时
                        skillClass: this.EditTwoModel.skillClass, //技术难度分类
                        skill: this.EditTwoModel.skill, //技术难度
                        riskClass: this.EditTwoModel.riskClass, //风险系数分类
                        risk: this.EditTwoModel.risk, //风险系数
                        company: "次", //单位
                        currentPrice: this.EditTwoModel.currentPrice, //现行价
                        offset: this.EditTwoModel.offset, //标化价值偏移
                        medicalSort:this.EditTwoModel.medadSub,//医嘱小类
                      projectScore:this.EditTwoModel.proCoeff,
                    });
                    this.editDepartmentBusiness(data, "edit");
                }
            });
        },
        // 修改添加接口
        async editDepartmentBusiness(data, isedit) {
            let res = await this.$axios.editDepartmentBusiness(data);
            // console.log(res);
            this.cesuanClick = true;
            this.editItemLoading = false;
            if (res.data.code == 401) {
                this.addItemLoading = false;
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                this.addItemLoading = false;
                this.adddialogVisible = false;
                this.$message({
                    message: "添加/修改科室医疗服务项目成功",
                    type: "success",
                });
                // 是添加就清除 是修改就不清除
                if (isedit !== "edit") {
                    this.$refs.AddTwoRef.resetFields();
                }
                this.editdialogVisible = false;
                this.getBusinessList();
            } else {
                this.addItemLoading = false;
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 科室服务项目列表 分页
        async getBusinessList() {
            let data = {
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                departmentId: this.departId, //科室id
                type: this.typeValue, //项目类型 0 非收费项目 1 收费项目 不传这个参数就是全部
                searchValue: this.searchValue, //搜索值
            };
            let { data: res } = await this.$axios.getBusinessList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室服务项目列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-input.el-input--suffix {
    width: 202px;
}

/deep/ .oneBack {
    background: #c4dfec;
}

.el-icon-star-on {
    cursor: pointer;
    font-size: 23px;
    position: relative;
    bottom: 6px;
    right: 4px;
}

.startActive {
    color: rgb(250, 200, 88);
}

.startBtn {
    width: 44px;
    height: 28px;
    position: relative;
    top: 10px;
}

.startBtnJizu {
    width: 44px;
    height: 28px;
    position: relative;
    top: 6px;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}

.el-pagination {
    margin-top: 10px;
    margin-bottom: 10px;
}

.twoForm {
    float: right;
    display: flex;
}

.depar-dia {
    width: 100%;
}

/deep/ .el-select.xiangType .el-input--suffix {
    width: 150px !important;
}

.icon-biaojiweizhongdian2-copy {
    cursor: pointer;
    font-size: 19px;
    position: relative;
    bottom: 3px;
    right: 4px;
}

.btnCen {
    text-align: center;
    .el-button {
        width: 150px;
    }
}
</style>
